/* Base */
@import './base/reset';
@import './base/vars';
@import './base/base';
@import './base/utils';
@import './base/containers';
@import './base/sticky-footer';

/* Blocks */
@import '../../node_modules/bootstrap/scss/bootstrap';

.steps {
	height: 100vh;
}

.form-inline {
	display: flex;
	align-items: center;
	justify-content: center;
	.form-floating {
		flex: 1;
	}
}

.vertical-divider {
	width: 1px;
	height: 40px; /* Adjust height as needed */
	background-color: #ced4da;
	margin: 0 10px;
}

.form-check {
	display: flex;
	align-items: center;
	.form-check-input {
		margin-right: 10px;
	}
}

.custom-divider {
	border-top: 2px solid #007bff; /* Цвет и толщина линии */
	margin: 0.5rem 0; /* Отступы сверху и снизу */
}
