html,
body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.footer {
	margin-top: auto;
}

.footer {
	padding: 60px 0;
	background-color: #e3e3e3;
}
